<template>
  <Modal :dismissible="true" :with-footer="true">
    <template v-slot:default>
      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('stocks.batches.list.id')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{batch.getId()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('stocks.batches.list.status')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          <Status :status="batch.getStatus()"/>
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('stocks.batches.list.farm')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{batch.getFarm().getName()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold" v-if="!batch.isTypeOffers()">{{$t('stocks.batches.list.interval')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold" v-if="!batch.isTypeOffers()">
          <Date :date="batch.getStartAt()"/> - <Date :date="batch.getEndAt()"/>
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('stocks.batches.list.available_products')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Qty elementStyle="pl-0 pr-0" :amount="batch.getItemCollection().getSize()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('stocks.batches.list.created_at')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Date :date="batch.getCreatedAt()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('stocks.batches.list.completed_at')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Date :date="batch.getCompletedAt()" />
        </dd>
      </dl>
    </template>
    <template v-slot:footer>
      <Button @click="closeAndGo" class="mr-auto" :name="$t('stocks.batches.buttons.edit')" type="eye"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Status from "@/components/elements/Status";
import Button from "@/components/elements/Button";
import Date from "@/components/elements/Date";
import Qty from "@/components/elements/Qty";

export default {
  name: "StockBatchDetailsModal",
  components: {Qty, Date, Button, Status, Modal},
  emits: ['send'],
  props: {
    batch: Object,
  },
  methods: {
    closeAndGo: function () {
      this.$router.push({name: 'general.breadcrumb.stocks.batches.edit', params: {'batchId': this.batch.getId()}});
      this.$emit('closemodal');
    },
  }
}
</script>

<style scoped>
</style>
