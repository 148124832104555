<template>
  <Modal :with-footer="true" :name="$t(details.title, {farmer: batch.getFarm().getName()})" @closemodal="$emit('closemodal')" :dismissible="true">
    <template v-slot:default>

      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 font-weight-semibold">{{$t('stocks.batches.list.id')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-semibold">
          {{batch.getId()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 font-weight-semibold" v-if="!batch.isTypeOffers()">{{$t('stocks.batches.list.interval')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-semibold" v-if="!batch.isTypeOffers()">
          <Date :date="batch.getStartAt()"/> - <Date :date="batch.getEndAt()"/>
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 font-weight-light" v-if="!batch.isTypeOffers()">{{$t('stocks.batches.list.available_products')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light" v-if="!batch.isTypeOffers()">
          <Qty elementStyle="pl-0 pr-0" :amount="batch.getItemCollection().getSize()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 font-weight-light">{{$t('stocks.batches.list.created_at')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light">
          <Date :date="batch.getCreatedAt()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 font-weight-light" v-if="batch.isTypeOffers()">{{$t('stocks.batches.list.customer_name')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light" v-if="batch.isTypeOffers()">
          {{batch.getCustomerName()}}
        </dd>
      </dl>
      <br class="clearfix"/>
      <br class="clearfix"/>
      <p>{{ $t(details.body) }}</p>
      <div v-bind:class="!copied ? 'alert-info':'alert-success'" class="alert alert-styled-left border-top-0 border-bottom-0 border-right-0">
        <span v-if="isScreenLgWidth" class="font-weight-semibold text-wrap">{{link}}</span>
        <span v-else class="font-weight-semibold text-wrap">{{ $t(copyText) }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <Button v-clipboard:success="onCopy" v-clipboard:copy="link" type="copy" :name="$t('stocks.batches.buttons.copy')"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import {ScreenSizeMixin} from "@/mixins/GeneralMixin";
import Date from "@/components/elements/Date";
import Qty from "@/components/elements/Qty";

export default {
  name: "StockBatchStatusModal",
  mixins: [ScreenSizeMixin],
  components: {Qty, Date, Modal, Button},
  emits: ['closemodal'],
  props: {
    batch: Object
  },
  data() {
    return {
      copied: false,
      copyText: 'general.url_copy',
    }
  },
  computed: {
    link: function () {
      let routeName = 'frontoffice.breadcrumb.farmers.offers.dashboard';
      if (this.batch.isTypeOffers()) {
        routeName = 'frontoffice.breadcrumb.farmers.offers.en-gross';
      }

      let props = this.$router.resolve({
        name: routeName,
        params: { id: this.batch.getFarm().getId(), batchId: this.batch.getId() },
      });

      return window.location.origin + props.href;
    },
    details: function () {

      if (this.batch.isInProgress()) {
        if (!this.batch.isTypeOffers()) {
          return {body: 'stocks.batches.list.status_in_progress_body', title: 'stocks.batches.list.status_in_progress_title'};
        }
        else {
          return {body: 'stocks.batches.list.status_in_progress_body', title: 'stocks.batches.list.status_in_progress_title_offers'};
        }
      }

      return {body: "", title: ""};
    },
  },
  methods: {
    onCopy: function () {
      this.copied = true;
      this.copyText = "general.url_copied";
    },
  }
}
</script>

<style scoped>

</style>
