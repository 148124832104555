<template>
  <Table @page="onPage" :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
        {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="elements.length">
      <TableElement @click="$emit('details', element)" v-for="element in elements" v-bind:key="element.getId()" :class="{'bg-warning': element.hasChangedItems() && element.isDone()}">
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{element.getId()}}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <FarmerName :farmer="element.getFarm()" />
            <i v-if="element.hasEnGrossOffers()" class="icon-coin-dollar ml-1"/>
            <div v-if="element.isTypeOffers()">
              <span class="badge badge-info" >{{$t('stocks.batches.list.offers')}}: {{element.getCustomerName()}}</span>
            </div>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Qty :amount="element.getItemCollection().getSize()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Date :date="element.getCreatedAt()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Date :date="element.getCompletedAt()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Status class="text-wrap" :status="element.getStatus()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Button :key="rerender" v-if="canSendStockBatches && !element.canShowStatusModal() && getSendButtonIconProps(element)" v-bind:disabled="!element.isProcessable()" @click.stop="sendBatch(element)" :type="getSendButtonIconProps(element).type" :name="getSendButtonIconProps(element).message"></Button>
            <Button :class="{'btn-light': element.isDone() && element.hasChangedItems()}" v-else-if="element.canShowStatusModal()" @click.stop="showStatusModal(element)" type="eye" name="stocks.batches.buttons.show"></Button>
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="!elements.length" >
      <TableElement :size="headers.length" :empty-message="$t('stocks.batches.list.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Button from "@/components/elements/Button";
import EmittedEvent from "@/entities/EmittedEvent";
import Status from "@/components/elements/Status";
import {AuthorizedActionsMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import FarmerName from "@/components/farmer/backoffice/FarmerName";
import Qty from "@/components/elements/Qty";
import Date from "@/components/elements/Date";
import {ConfirmationModalMixin} from "@/mixins/ModalMixin";

export default {
  name: "StockBatchTable",
  components: {Date, Qty, FarmerName, Status, Button, TableElement, TableElementItem, Table},
  mixins: [ScreenSizeMixin, ConfirmationModalMixin, AuthorizedActionsMixin],
  emits: ['page', 'askConfirmation', 'show', 'details'],
  props: {
    elements: Array,
    pagination: {
      type: Object
    }
  },
  data: function() {
    return {
      batch: null,
      allHeaders: [
          'stocks.batches.list.id',
          'stocks.batches.list.farm',
          'stocks.batches.list.available_products',
          'stocks.batches.list.created_at',
          'stocks.batches.list.completed_at',
          'stocks.batches.list.status',
          'stocks.batches.list.action',
      ],
      responsiveHeaders: [
        'stocks.batches.list.farm',
        'stocks.batches.list.created_at',
        'stocks.batches.list.status',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    rerender: function () {
      return this.$store.state.rerender;
    },
  },
  methods: {
    onPage(pageNr) {
      this.$emit('page', pageNr);
    },
    showStatusModal(batch) {
      this.$emit('show', batch);
    },
    sendBatch(batch) {
      let type = this.extractTypeFromBatch(batch);
      if (type) {
        this.notifyForAskConfirmation(new EmittedEvent(type, batch))
      }
    },
    extractTypeFromBatch(batch) {
      let type = '';

      if (batch.isNew()) {
        type = 'stocks.batches.list.confirm.send';
      } else {
        console.log('Batch status is not recognized');
      }

      return type;
    },
    getSendButtonIconProps(batch)
    {
      switch (this.extractTypeFromBatch(batch)) {
        case "":
          return null;
        default:
          return {type: 'send', message:'stocks.batches.buttons.send'};
      }
    },
  }
}
</script>

<style scoped>

</style>
