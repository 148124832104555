<template>
  <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
  <StockBatchStatusModal @closemodal="closeStatusModal" v-if="batch" :batch="batch" />
  <StockBatchDetailsModal @send="askConfirmation" @closemodal="selectedBatch=null" v-if="selectedBatch" :batch="selectedBatch" />
  <GenerateAllModal v-if="canGenerateAll" @closemodal="canGenerateAll=false" @generate="generateStockBatches"/>
  <GenerateSingleModal v-if="canGenerateSingle" @closemodal="canGenerateSingle=false" @generate="generateSingleStockBatch"/>
  <GenerateOffersModal v-if="canGenerateOffers" @closemodal="canGenerateOffers=false" @generate="generateOffers"/>
  <div v-if="isBatchListRoute" class="row">
    <div class="col-md-12">
      <Card title="stocks.batches.list.title" :with-footer="false">
        <template v-slot:subtitle>
          <div class="d-flex">
          <Button class="w-100 mt-2 w-lg-auto d-block" v-if="canGenerateStockBatches" type="success" @click="canGenerateSingle=true" name="stocks.batches.buttons.generate.single" :disabled="isLoading"></Button>
          <Button class="w-100 mt-2 ml-2 w-lg-auto d-block" v-if="canGenerateStockBatches" type="success" @click="canGenerateAll=true" name="stocks.batches.buttons.generate.all" :disabled="isLoading"></Button>
          <Button class="w-100 mt-2 ml-2 w-lg-auto d-block" v-if="canGenerateStockBatches" type="success" @click="canGenerateOffers=true" name="stocks.batches.buttons.generate.offers" :disabled="isLoading"></Button>
          </div>
        </template>
        <template v-slot:default>
          <StockBatchTable @details="showBatchDetails" @show="showBatchStatusModal" @ask-confirmation="askConfirmation" @page="getBatches" :elements="batches.getItems()"
                      :pagination="batches.getPagination()"></StockBatchTable>
        </template>
      </Card>
    </div>
  </div>
  <router-view @ask-confirmation="askConfirmation" :existing-batches="batches.getPagination() && batches.getPagination().getTotal()" @update="init()" @show="showBatchStatusModal" />
</template>

<script>
import Card from "@/components/elements/Card";
import StockBatch from "@/entities/StockBatch";
import Pagination from "@/entities/Pagination";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import {LoadingModalMixin, ConfirmationModalMixin} from "@/mixins/ModalMixin";
import {AuthorizedActionsMixin, DatesMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import StockBatchTable from "@/components/stock-batch/backoffice/StockBatchTable";
import LoadableCollection from "@/entities/LoadableCollection";
import GenerateAllModal from "@/components/stock-batch/backoffice/GenerateAllModal";
import Button from "@/components/elements/Button";
import StockBatchDetailsModal from "@/components/stock-batch/backoffice/StockBatchDetailsModal";
import StockBatchStatusModal from "@/components/stock-batch/backoffice/StockBatchStatusModal";
import GenerateSingleModal from "@/components/stock-batch/backoffice/GenerateSingleModal";
import GenerateOffersModal from "@/components/stock-batch/backoffice/GenerateOffersModal";

export default {
  name: "StockBatchList",
  components: {
    GenerateOffersModal,
    GenerateSingleModal,
    StockBatchStatusModal,
    StockBatchDetailsModal,
    Button, GenerateAllModal, StockBatchTable, ConfirmationModal, Card},
  mixins: [LoadingModalMixin, ConfirmationModalMixin, ScreenSizeMixin, AuthorizedActionsMixin, DatesMixin],
  data: function () {
    return {
      batches: new LoadableCollection(),
      canGenerateAll: false,
      canGenerateSingle: false,
      canGenerateOffers: false,

      batch: null, //used for status modal
      selectedBatch: null, //used for batch details modal
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    isBatchListRoute()
    {
      return this.$router.currentRoute.value.name === 'general.breadcrumb.stocks.batches.list';
    },
  },
  methods: {
    onLoadingComplete()
    {
      if (!this.initialise) {
        return;
      }

      this.init();
    },
    showBatchStatusModal(batch)
    {
      if (null === batch) {
        return;
      }

      this.batch = batch;
    },
    closeStatusModal()
    {
      this.batch = null;
    },
    confirmed()
    {
      switch (this.confirmationType) {
        case "stocks.batches.list.confirm.send":
          this.sendBatchToInProgress(this.confirmedEvent.getPayload());
          break;
      }
      this.closeConfirmation();
    },
    reset() {
      this.batches.unloadAndEmpty();
      this.closeConfirmation();
    },
    init() {
      this.reset();
      this.getBatches();
      this.$store.commit('rerender');
    },
    getBatches(pageNr = 1) {
      this.hydrateBatches(this.batches, {}, pageNr);
    },
    generateStockBatches: function (startAt, endAt) {
      if (!startAt || !endAt) {
        return;
      }

      let that = this;
      let data = {
        'type': 'full',
        'start_at': this.convertLocalDateToUTCExactly(startAt),
        'end_at': this.convertLocalDateToUTCExactly(endAt),
      };

      this.canGenerateAll = false;
      this.queueJob();
      this.axios.post(this.$store.state.config.getGenerateStocksBatchesUri(), data).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    generateSingleStockBatch: function (startAt, endAt, farmer, forAllProducts, exactItems) {
      if (!startAt || !endAt || !farmer) {
        return;
      }

      let data = {
        'start_at': this.convertLocalDateToUTCExactly(startAt),
        'end_at': this.convertLocalDateToUTCExactly(endAt),
        'farmer_id': parseInt(farmer),
        'all_products': forAllProducts,
        'type': 'few',
        'selected_products': exactItems.map((item) => {
          return {
            'product_id': item.getProductId(),
            'product_name': item.getProductName(),
            'qty': item.justGetQty(),
            'desired_price': item.getDesiredPriceWithVat(),
          }
        })
      };

      let that = this;
      this.canGenerateSingle = false;
      this.queueJob();
      this.axios.post(this.$store.state.config.getGenerateStocksBatchesUri(), data).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },

    generateOffers(farmer, customerName) {
      if (!farmer || !customerName) {
        return;
      }

      let data = {
        'farmer_id': parseInt(farmer),
        'customer_name': customerName,
        'type': 'offers'
      };

      let that = this;
      this.canGenerateOffers = false;
      this.queueJob();
      this.axios.post(this.$store.state.config.getGenerateStocksBatchesUri(), data).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },

    sendBatchToInProgress(batch) {
      let that = this;
      this.queueJob();
      this.axios.put(this.$store.state.config.getSendToInProgressStockBatchUri(batch.getId())).then(
          response => {
            let content = response.data;
            that.showBatchStatusModal(new StockBatch(content.data));
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    hydrateBatches(batches, filter, pageNr = 1) {
      let that = this;
      let localBatches = new LoadableCollection();
      let filterParams = {
        page: pageNr,
      };

      if (typeof filter.status !== "undefined") {
        filterParams.status = filter.status;
      }

      that.queueJob();
      batches.unloadAndEmpty();
      this.axios.get(this.$store.state.config.getStocksBatchesUri(), {params: filterParams}).then(
          response => {
            let content = response.data;

            localBatches.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (batch) {
              localBatches.push(new StockBatch(batch));
            });
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        batches.setPagination(localBatches.getPagination());
        localBatches.getItems().forEach(batch => {
          batches.push(batch);
        });
        batches.loaded();
        that.completeJob();
      });
    },
    showBatchDetails(batch) {
      if (!this.isScreenLgWidth) {
        this.selectedBatch = batch;
      } else {
        this.$router.push({name: 'general.breadcrumb.stocks.batches.edit', params: {'batchId': batch.getId()}})
      }
    }
  }
}
</script>

<style scoped>

</style>
