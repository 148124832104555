<template>
  <Modal :dismissible="true" :with-footer="true" :name="item ? item.getProductName() : ''">
    <template v-slot:default>
      <div >
        <dl class="row mb-5">
          <dt class="col-4 col-md-2 font-weight-semibold">{{$t('stocks.batches.generate.farm')}}</dt>
          <dd class="col-8 col-md-10 font-weight-semibold">
            <Select2 v-if="farmOptions.length > 0" class="w-75 w-lg-50 no-mobile-zoom" v-model="selectedFarm" :options="farmOptions" :settings="farmSelect2Settings"/>
            <Spinner v-else :simple="true" class="ml-2 icon-2x"/>
            <div class="input-errors" v-for="(error, index) of v$.selectedFarm.$errors" :key="index">
              <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
            </div>
          </dd>
          <dt class="col-4 col-md-2 font-weight-semibold">{{$t('stocks.batches.generate.customer_name')}}</dt>
          <dd class="col-8 col-md-10 font-weight-semibold">
            <TextInput type="text" :class="{'error':v$.customerName.$error}" class="d-block w-75 w-lg-50" :value="customerName"
                       :reference="item" @input="updateCustomerName" />
            <div class="input-errors d-block" v-for="(error, index) of v$.customerName.$errors" :key="index">
              <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
            </div>
          </dd>
        </dl>
      </div>
    </template>
    <template v-slot:footer>
      <Button :disabled="!canEnableActionButtons" @click.stop="generate()" type="next" :name="wizardButtonNextName" class="ml-auto"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import {DatesMixin, ValidationsMixin} from "@/mixins/GeneralMixin";
import DatePickerMixin from "@/mixins/DatePickerMixin";
import {required} from "@vuelidate/validators";
import Farmer from "@/entities/Farmer";
import useVuelidate from "@vuelidate/core";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import Select2 from "vue3-select2-component";
import Spinner from "@/components/elements/Spinner";
import TextInput from "@/components/elements/TextInput";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "GenerateOffersModal",
  components: {TextInput, Spinner, Button, Modal, Select2},
  emits: ['generate'],
  mixins: [DatesMixin, DatePickerMixin, ValidationsMixin, LoadingModalMixin],
  validations: {
    selectedFarm: {
      required,
    },
    customerName: {
      required
    }
  },
  watch: {
    selectedFarm: {
      handler: async function (farmerId) {
        console.log(farmerId);
      }
    },
  },
  async beforeMount() {
    this.farmOptions = await this.getFarms();
  },
  computed: {
    rerender: function () {
      return this.$store.state.rerender;
    },
    wizardButtonNextName: function () {
      return "stocks.batches.buttons.generate.create_offer_request";
    },
    isFormReady: function () {
      return !(this.v$.selectedFarm.$error);
    },
    canEnableActionButtons: function () {
      return this.isFormReady && !this.v$.customerName.$error;
    },
  },
  data: function() {
    let that = this;
    return {
      today: new Date(),

      item: null,
      selectedFarm: null,
      farmOptions: [],

      customerName: '',

      farmSelect2Settings: {
        placeholder: this.$i18n.t('stocks.batches.generate.farm'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      }
    }
  },
  methods: {
    generate: function () {
      this.validateInputs();
      if (!this.v$.customerName.$error && !this.v$.selectedFarm.$error) {
        this.$emit("generate", this.selectedFarm, this.customerName);
      }
    },
    updateCustomerName: function (item, price) {
      this.customerName = price;
      this.v$.customerName.$touch();
    },
    validateInputs: function () {
      this.v$.selectedFarm.$touch();
      this.v$.customerName.$touch();
    },
    getFarms: function (pageNr = 1) {
      let filterParams = {
        page: pageNr,
        per_page: 1000
      };

      return this.axios.get(this.$store.state.config.getFarmersUri(), {params: filterParams}).then(
          response => {
            let options = [];
            let content = response.data;

            content.data.data.forEach(function (farmer) {
              farmer = new Farmer(farmer);
              if (farmer.isActive()) {
                options.push({
                  id: farmer.getId(),
                  text: farmer.getName(),
                });
              }
            });

            return Promise.resolve(options);
          }
      ).catch(
          error => {
            console.warn(error);
            return Promise.resolve([]);
          }
      );
    },
  }
}
</script>

<style scoped>

</style>
