<template>
  <Modal :dismissible="true" :with-footer="true" :name="item ? item.getProductName() : ''">
    <template v-slot:default>
      <div v-if="-1 === progress">
        <dl class="row mb-5">
          <dt class="col-4 col-md-2 font-weight-semibold">{{$t('stocks.batches.generate.farm')}}</dt>
          <dd class="col-8 col-md-10 font-weight-semibold">
            <Select2 v-if="farmOptions.length > 0" class="w-75 w-lg-25 no-mobile-zoom" v-model="selectedFarm" :options="farmOptions" :settings="farmSelect2Settings"/>
            <Spinner v-else :simple="true" class="ml-2 icon-2x"/>
            <div class="input-errors" v-for="(error, index) of v$.selectedFarm.$errors" :key="index">
              <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
            </div>
          </dd>
          <dt class="col-4 col-md-2 font-weight-semibold">{{$t('stocks.batches.generate.interval')}}</dt>
          <dd class="col-8 col-md-10 font-weight-semibold">
            <div class="d-lg-inline-flex w-100 verde">
              <span class="mb-auto mt-auto text-center">{{ $t("stocks.batches.generate.start_at") }}</span>
              <DatePicker inputFormat="dd-MM-yyyy" v-model="startAt" :lowerLimit="today" :clearable="true" class="ml-lg-2 d-lg-inline w-75 no-mobile-zoom" :placeholder="$t('stocks.batches.generate.placeholder.start_at')"/>
              <span class="mb-auto mt-auto ml-lg-3">{{ $t("stocks.batches.generate.end_at") }}</span>
              <DatePicker inputFormat="dd-MM-yyyy" :key="startAt" :disabled="!startAt" :lowerLimit="startAt" :clearable="true" v-model="endAt" type="text" class="ml-lg-2 d-lg-inline w-75 no-mobile-zoom" :placeholder="$t('stocks.batches.generate.placeholder.end_at')"/>
            </div>
          </dd>
          <dt class="col-4 col-md-2 font-weight-semibold d-flex"><input class="ml-auto mt-auto mb-auto" type="checkbox" :disabled="!selectedFarm" :checked="allProducts" v-model="allProducts"/></dt>
          <dd class="col-8 col-md-10 font-weight-semibold">
            {{$t('stocks.batches.generate.all_products')}}
          </dd>
        </dl>
        <div v-if="!allProducts">
          <label>{{$t('stocks.batches.generate.product')}}</label>
          <Select2  v-if="productOptions.length > 0" class="w-100 no-mobile-zoom" v-model="selectedProducts" :options="productOptions" :settings="productSelect2Settings"/>
          <Spinner v-else :simple="true" class="ml-2 icon-2x d-block"/>
        </div>
      </div>
      <div :key="rerender" v-if="progress >= 0 && item">
        <div class="row mb-2">
          <div class="col-12 col-lg-6">{{$t('stocks.batches.existing.estimated_qty')}}</div>
          <div class="col-12 col-lg-6">
            <SelectInput class="col-8 col-lg-9 d-inline" :class="{'error':v$.qty.$error}" :reference="item" :options="qtyOptions" @input="updateQty"
                         :selected="qty"/>
            <span class="col-3 d-inline"> /{{ $t('stocks.batches.existing.weekly') }}</span>
          </div>
          <div class="input-errors" v-for="(error, index) of v$.qty.$errors" :key="index">
            <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 col-lg-6">{{$t('stocks.batches.existing.list_price')}}</div>
          <div class="col-3 col-lg-6">
            <Price :amount="item.getInitialPriceWithVat()" :currency="item.getCurrency()" />&nbsp;/&nbsp;{{item.getUnit()}}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">{{$t('stocks.batches.existing.desired_price', {currency: item.getCurrency()})}}</div>
          <div class="col-12 col-lg-6">
            <TextInput type="number" :class="{'error':v$.desiredPrice.$error}" class="col-8 col-lg-9 d-inline" :value="desiredPrice"
                       :reference="item" @input="updateDesiredPrice"/>
            <span class="col-3 d-inline"> &nbsp;{{ item.getCurrency() }}&nbsp;/&nbsp;{{item.getUnit()}}</span>
            <div class="col-12 input-errors" v-for="(error, index) of v$.desiredPrice.$errors" :key="index">
              <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="progress >= selectedProducts.length">
        <h3>{{$t('stocks.batches.existing.product_selection')}}</h3>
        <ul>
          <li :key="item.getId()" v-for="item in selectedItems">{{item.getProductName()}}, <Qty element-style="d-inline p-0 m-0" :amount="item.justGetQty()" :unit="item.getUnit()" /><span class="p-0 col-2 d-inline">/{{ $t('stocks.batches.existing.weekly') }}</span>, <Price :amount="item.justGetDesiredPriceWithVat()" :currency="item.getCurrency()" /></li>
        </ul>
      </div>
    </template>
    <template v-slot:footer>
      <Button v-if="progress >= 0 " :disabled="!canEnableActionButtons" @click.stop="prevWizardStep()" type="prev" name="stocks.batches.buttons.generate.prev" class="mr-auto"/>
      <Button :disabled="!canEnableActionButtons" @click.stop="nextWizardStep()" type="next" :name="wizardButtonNextName" class="ml-auto"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import {DatesMixin, ValidationsMixin} from "@/mixins/GeneralMixin";
import DatePicker from "vue3-datepicker";
import DatePickerMixin from "@/mixins/DatePickerMixin";
import {decimal, minValue, required} from "@vuelidate/validators";
import Farmer from "@/entities/Farmer";
import useVuelidate from "@vuelidate/core";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import Select2 from "vue3-select2-component";
import Spinner from "@/components/elements/Spinner";
import Product from "@/entities/Product";
import SelectInput from "@/components/elements/SelectInput";
import TextInput from "@/components/elements/TextInput";
import Price from "@/components/elements/Price";
import StockBatchItem from "@/entities/StockBatchItem";
import Qty from "@/components/elements/Qty";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "GenerateSingleModal",
  components: {Qty, Price, TextInput, Spinner, Button, Modal, DatePicker, Select2, SelectInput},
  emits: ['generate'],
  mixins: [DatesMixin, DatePickerMixin, ValidationsMixin, LoadingModalMixin],
  validations: {
    selectedFarm: {
      required,
    },
    qty: {
      required,
    },
    desiredPrice: {
      required,
      decimal,
      priceMinValue: minValue(0.01),
    },
  },
  watch: {
    startAt: {
      handler: function () {
        this.endAt = null;
      }
    },
    selectedFarm: {
      handler: async function (farmerId) {
        this.allProducts = true;
        this.items = {};
        this.item = null;
        this.progress = -1;
        this.selectedProducts = [];
        this.productOptions = [];
        this.productOptions = await this.getProducts(farmerId);
      }
    },
  },
  async beforeMount() {
    this.farmOptions = await this.getFarms();
  },
  computed: {
    rerender: function () {
      return this.$store.state.rerender;
    },
    wizardButtonNextName: function () {
      if (this.progress === -1 && !this.allProducts) {
        return "stocks.batches.buttons.generate.configure_next";
      } else if (this.allProducts) {
        return "stocks.batches.buttons.generate.create";
      } else if (this.progress >= this.selectedProducts.length && null === this.item) {
        return "stocks.batches.buttons.generate.create_simple";
      }

      return "stocks.batches.buttons.generate.next";
    },
    selectedItems: function () {
      return this.selectedProducts.map((productId) => this.items[parseInt(productId)]);
    },
    isFormReady: function () {
      return !(this.v$.selectedFarm.$error);
    },
    canEnableActionButtons: function () {
      return this.isFormReady && this.startAt && this.endAt && (this.allProducts || this.selectedProducts.length > 0) &&
          (this.progress === -1 || (!this.v$.qty.$error && !this.v$.desiredPrice.$error));
    },
    qtyOptions: function () {
      let values = [];
      let i = 0;
      while (i <= 500) {
        values.push({
          val: i,
          txt: i + " " + this.item.getUnit(),
        })
        if (i < 10) {
          i++;
        }
        else if ( i < 50 ) {
          i += 5;
        }
        else if ( i < 100 ) {
          i += 10;
        }
        else {
          i += 50;
        }
      }

      return values;
    },
  },
  data: function() {
    let that = this;
    return {
      allProducts: true,
      startAt: null,
      endAt: null,
      today: new Date(),

      item: null,
      selectedFarm: null,
      selectedProducts: [],
      progress: -1,

      items: {},
      farmOptions: [],
      productOptions: [],

      qty: 0,
      desiredPrice: 0,

      farmSelect2Settings: {
        placeholder: this.$i18n.t('stocks.batches.generate.farm'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      productSelect2Settings: {
        placeholder: this.$i18n.t('stocks.batches.generate.product_search'),
        width: '100%',
        dropdownAutoWidth: true,
        multiple: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
    }
  },
  methods: {
    nextWizardStep: function () {

      if (
          this.allProducts ||
          (this.progress >= this.selectedProducts.length && null === this.item)
      ) {
        this.generate();
        return;
      }

      if (this.item) {
        this.v$.qty.$touch();
        this.v$.desiredPrice.$touch();

        if (!this.canEnableActionButtons) {
          return;
        }

        this.item.setDesiredPriceWithVat(this.desiredPrice);
        this.item.setQty(this.qty);
      }
      if (this.progress < this.selectedProducts.length) {
        this.progress++;
      }

      if (this.progress < this.selectedProducts.length) {
        this.item = this.items[parseInt(this.selectedProducts[this.progress])];
        this.desiredPrice = this.item.justGetDesiredPriceWithVat();
        this.qty = this.item.justGetQty();
      } else {
        this.item = null;
      }

      this.$store.commit('rerender');
    },
    prevWizardStep: function () {
      if (this.item) {
        this.v$.qty.$touch();
        this.v$.desiredPrice.$touch();

        if (!this.canEnableActionButtons) {
          return;
        }

        this.item.setDesiredPriceWithVat(this.desiredPrice);
        this.item.setQty(this.qty);
      }

      if (this.progress >= this.selectedProducts.length) {
        this.progress = this.selectedProducts.length;
      }

      if (this.progress > -2) {
        this.progress--;
      }

      if (this.progress > -1) {
        this.item = this.items[parseInt(this.selectedProducts[this.progress])];
        this.desiredPrice = this.item.justGetDesiredPriceWithVat();
        this.qty = this.item.justGetQty();
      } else {
        this.item = null;
      }

      this.$store.commit('rerender');
    },
    updateQty: function (item, qty) {
      this.qty = qty;
      this.v$.qty.$touch();
    },
    updateDesiredPrice: function (item, price) {
      this.desiredPrice = price;
      this.v$.desiredPrice.$touch();
    },
    generate: function () {
      this.$emit("generate", this.startAt, this.endAt, this.selectedFarm, this.allProducts, this.selectedItems);
    },
    validateInputs: function () {
      this.v$.selectedFarm.$touch();
    },
    getFarms: function (pageNr = 1) {
      let filterParams = {
        page: pageNr,
        per_page: 1000
      };

      return this.axios.get(this.$store.state.config.getFarmersUri(), {params: filterParams}).then(
          response => {
            let options = [];
            let content = response.data;

            content.data.data.forEach(function (farmer) {
              farmer = new Farmer(farmer);
              if (farmer.isActive()) {
                options.push({
                  id: farmer.getId(),
                  text: farmer.getName(),
                });
              }
            });

            return Promise.resolve(options);
          }
      ).catch(
          error => {
            console.warn(error);
            return Promise.resolve([]);
          }
      );
    },
    getProducts: function (farmerId, pageNr = 1) {
      let that = this;
      let filterParams = {
        page: pageNr,
        per_page: 1000
      };

      return this.axios.get(this.$store.state.config.getProductsUri(farmerId), {params: filterParams}).then(
          response => {
            let options = [];
            let content = response.data;

            content.data.data.forEach(function (product) {
              product = new Product(product);
              //ignore products with 0 as price
              if (0 > product.getBuyingPriceWithVat()) {
                return;
              }

              that.items[product.getId()] = new StockBatchItem({
                product_name: product.getDisplayName(),
                currency: product.getCurrency(),
                product_id: product.getId(),
                initial_price: product.getBuyingPriceWithVat(),
                desired_price: product.getBuyingPriceWithVat(),
                initial_qty: !product.getStock().isAvailable() ? 0 : 100,
                unit: product.getUnit()
              });

              options.push({
                id: product.getId(),
                text: product.getDisplayName(),
              });
            });

            return Promise.resolve(options);
          }
      ).catch(
          error => {
            console.warn(error);
            return Promise.resolve([]);
          }
      );
    }
  }
}
</script>

<style scoped>

</style>
